
































































































































import Ueditor from "@/components/Ueditor/index.vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType,
  DataDictionaryDtoPagedResultDto,
  FundApplyCreateOrUpdateDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";

@Component({
  components: {
    AbSelect,
    Ueditor,
    MultipleUploadFile,
    SimpleUploadImage,
  },
})
export default class CreateFundMaintain extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId?: number = 0;
  form: FundApplyCreateOrUpdateDto = {
    canAnonymous: true,
  };
  radio = "";
  typeList: any = [];
  childList: any = [];
  honorTypeList: any = [];
  checkList: any = [];
  fundRange: any = [];
  sex = "";
  yesOrNoList = [
    {
      key: "是",
      value: true,
    },
    {
      key: "否",
      value: false,
    },
  ];
  btnDisabled = false;

  get hostTypeFile() {
    return AttachmentHostType.FundApply;
  }

  created() {
    this.fetchEnumType();
    this.fetchDataDictionary();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "HonorType", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.honorTypeList = res.items;
      });
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "FundRange", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.fundRange = res.items;
      });
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "FundCategory", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.childList = res.items;
      });
  }

  async save() {
    this.btnDisabled = true;
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.checkList) {
          this.form.usageRange = this.checkList.join(",");
        }
        await api.fundApply
          .create({
            body: this.form,
          })
          .then(() => {
            this.$router.back();
            this.$message.success("新增成功");
          })
          .catch((reason) => {
            this.btnDisabled = false;
          });
      } else {
        this.$message.error("请检查表单");
        this.btnDisabled = false;
      }
    });
  }

  cancel() {
    this.$router.back();
    // (this.$refs.dataForm as any).resetFields();
    // this.$emit("input", false);
  }

  roleRule = {
    fundName: [
      {
        required: true,
        message: "基金名称必填",
        trigger: "blur",
      },
    ],
    type: [
      {
        required: true,
        message: "基金类型必填",
        trigger: "blur",
      },
    ],
    applicantName: [
      {
        required: true,
        message: "发起单位/个人必填",
        trigger: "blur",
      },
    ],
    intialCaptial: [
      {
        required: true,
        message: "起设资金金额必填",
        trigger: "blur",
      },
    ],
    applicantMobileTelephone: [
      {
        required: true,
        message: "手机号码必填",
        trigger: "blur",
      },
    ],
    applicantEmail: [
      {
        required: true,
        message: "邮箱必填",
        trigger: "blur",
      },
      {
        message: "请填写正确的邮箱地址",
        type: "email",
        trigger: "blur",
      },
    ],
  };
}
